export const DOTORIHAM_MAKE_PEOPLE = [
  {
    field: "PLAN",
    name: "신예람",
    activity: true,
  },
  {
    field: "DESIGN",
    name: "김서정",
    activity: true,
  },
  {
    field: "FRONT",
    name: "이도현",
    activity: true,
  },
  {
    field: "FRONT",
    name: "이지호",
    activity: true,
  },
  {
    field: "BACK",
    name: "노지환",
    activity: true,
  },
  {
    field: "BACK",
    name: "이주현",
    activity: true,
  },
];
